import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled, { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'

import Layout from '../../components/Layout'
import { device } from '../../components/Styles'

const Container = styled.div`
  background-color: #fff;
  padding: 1rem;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
`
const InnerContainer = styled.div`
  margin: 0rem auto;

  @media ${device.laptop} {
    width: var(--blog-post-width);
    padding: 0;
  }

  > h1 {
    text-align: center;
  }
`
const Content = styled.div`
  border-radius: 1rem;
  font-size: 1.1rem;
  line-height: 2rem;
  color: var(--color-gray-900);
`
const Date = styled.div`
  margin-bottom: 1rem;
  color: var(--color-gray-900);
  font-weight: 600;
`
const Body = styled.div`
  text-align: left;

  h1 {
    margin-bottom: 1rem;
    margin-top: 4rem;
    font-weight: 600;
    font-size: 1.6rem;
    color: #000;

    &::before {
      content: '> ';
    }
  }

  h2 {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid var(--color-pink-400);
    color: var(--color-pink-400);
  }
`
const Title = styled.h1`
  color: var(--color-pink-400);
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.5em;
  margin-top: 2rem;
`

const Global = createGlobalStyle`
  html, body {
    background-color: #fff;
  }
`

interface IData {
  mdx: {
    frontmatter: {
      date: string
      title: string
      cover: {
        childImageSharp: {
          gatsbyImageData: {
            images: {
              fallback: {
                src: string
              }
            }
          }
        }
      }
    }

    body: string
    excerpt: string
  }
  site: {
    siteMetadata: {
      siteUrl: string
    }
  }
}

interface Props {
  data: IData
}

const BlogPost: React.FC<Props> = ({ data }) => {
  const metaImage = data.mdx.frontmatter.cover.childImageSharp.gatsbyImageData.images.fallback.src
  const site = data.site

  return (
    <Layout>
      <Helmet
        title={data.mdx.frontmatter.title}
        titleTemplate="%s"
        meta={[
          { property: `og:image`, content: `${site.siteMetadata.siteUrl}${metaImage}` },
          { property: `og:image:secure_url`, content: `${site.siteMetadata.siteUrl}${metaImage}` },
          { name: `twitter:image`, content: `${site.siteMetadata.siteUrl}${metaImage}` },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:title', content: data.mdx.frontmatter.title },
          { name: 'twitter:description', content: data.mdx.excerpt },
          { property: 'og:type', content: 'article' },
          { property: 'og:title', content: data.mdx.frontmatter.title },
          { property: 'og:description', content: data.mdx.excerpt },
          { name: 'description', content: data.mdx.excerpt }
        ]}
      />
      <Container>
        <InnerContainer>
          <Title>{data.mdx.frontmatter.title}</Title>
          <Date>{data.mdx.frontmatter.date}</Date>
          <Content>
            <Body>
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </Body>
          </Content>
        </InnerContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        summary
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default BlogPost
